import { AccountData } from '../types';

export const ERROR_CODES = {
  STUDIO_ACCESS_DENIED: '8038',
  SSO_ERROR_USER_NOT_EXISTS: '1000',
  SSO_ERROR_NOT_ASSOCIATED_WITH_SSO: '1001',
  SSO_ERROR_FAILED_TO_ACQUIRE_ID_TOKEN_FROM_CODE: '8020',
  SSO_ERROR_EMAIL_ALREADY_EXIST: '8021',
  SSO_ERROR_MISSING_OPENID_AUTH_METHOD: '8022',
  SSO_ERROR_FEDERATED_AUTH_ID_MISSING_IN_SETTINGS: '8023',
  SSO_ERROR_USER_NOT_FOUND_AND_CAN_NOT_BE_CREATED: '8024',
  SSO_ERROR_BLOCKED_BY_CAPABILITY: '8030',
  GENERAL_ERROR_CODE: '-1',
  CAPTCHA_REQUIRED: '9981',
  CAPTCHA_FAILED: '9982',
  SECOND_FACTOR_REQUIRED: '8000',
  SECOND_FACTOR_INVALID_CODE: '8001',
  SECOND_FACTOR_INVALID_AUTH_TOKEN: '8002',
  SECOND_FACTOR_TOO_MANY_ATTEMPTS: '8004',
  SECOND_FACTOR_CODE_EXPIRED: '8006',
  ACCOUNT_RECOVERY_CODE_TOO_MANY_ATTEMPTS: '8010',
  ACCOUNT_RECOVERY_CODE_INVALID_CODE: '8011',
  SOCIAL_LOGIN_VERIFY_ACCOUNT: '9967',
  SOCIAL_LOGIN_GET_EMAIL: '9971',
  USER_DELETED_OR_BLOCKED: '9969',
  USER_IS_BLOCKED: '9990',
  USER_IS_DELETED: '9991',
  INVALID_EMAIL_OR_PASSWROD: '9960',
  PERSONAL_USER_ACCOUNT_NOT_FOUND: '9997',
  EMAIL_ALREADY_EXISTS: '9967',
  PASSWORD_INCORRECT: '9972',
  IAM_PASSWORD_INCORRECT: '-19976',
  USERNAME_ALREADY_EXISTS: '9964',
  INVALID_EMAIL_OR_PASSWORD: '9960',
  SSO_LOGIN_MANDATORY_ERROR: '8028',
  EDITOR_X_SIGNUP_DISABLED_ERROR: '8040',
  LOGIN_NO_SUCH_MAIL: '9966',
  LOGIN_NO_SUCH_MAIL_IAM: '-19999',
  RESET_PASSWORD_TOKEN_EXPIRED: '-15016',
  RESET_PASSWORD_TOKEN_ALREADY_USED: '-15015',
  RESET_PASSWORD_BAD_TOKEN: '-15003',
  RESET_PASSWORD_REQUIRED: '9986',
  RESET_PASSWORD_REQUIRED_IAM: '-19973',
  OFAC_COUNTRIES_EMAIL_ERROR: '9979',
  OFAC_COUNTRIES_LOCATION_ERROR: '9980',
  INVALID_PHONE_NUMBER: '-60000',
  STEP_UP_AUTH_REQUIRED: 'STEP_UP_AUTH_REQUIRED',
  EMAIL_ALREADY_EXISTS_IAM: '-19995',
  INVALID_TOKEN: '8090',
  INVALID_CODE: '8011',
  TOO_MANY_ATTEMPTS: '8010',
  ACCOUNT_HAS_SSO_SETTINGS: '8028',
  BLOCKED_DOMAIN: '8051',
  SOCIAL_MERGE_NOT_ALLOWED_USER_HAS_ENABLED_2FA: '8052',
  UNIMPLEMENTED_FEATURE: 'UNIMPLEMENTED_FEATURE',
};

export const extractServerErrorCode = (data: any) =>
  data?.errorCode?.toString() ??
  data?.response?.data?.details?.applicationError?.code ??
  ERROR_CODES.GENERAL_ERROR_CODE;

export const ErrorCodesToKeys = {
  [ERROR_CODES.GENERAL_ERROR_CODE]: 'errors.general',
  [ERROR_CODES.SECOND_FACTOR_INVALID_CODE]: 'enter_code.code_invalid_error',
  [ERROR_CODES.ACCOUNT_RECOVERY_CODE_INVALID_CODE]:
    'enter_code.code_invalid_error',
  [ERROR_CODES.SECOND_FACTOR_INVALID_AUTH_TOKEN]: 'errors.general',
  [ERROR_CODES.SECOND_FACTOR_TOO_MANY_ATTEMPTS]:
    'enter_code.too_many_attempts_error',
  [ERROR_CODES.ACCOUNT_RECOVERY_CODE_TOO_MANY_ATTEMPTS]:
    'enter_code.too_many_attempts_error',
  [ERROR_CODES.SECOND_FACTOR_CODE_EXPIRED]: 'enter_code.code_expired',
  [ERROR_CODES.SSO_ERROR_NOT_ASSOCIATED_WITH_SSO]:
    'errors.email.sso.notAssociated',
  [ERROR_CODES.SSO_ERROR_USER_NOT_EXISTS]: 'errors.email.sso.userNotExists',
  [ERROR_CODES.SSO_ERROR_BLOCKED_BY_CAPABILITY]: 'errors.blocked_by_capability',
  [ERROR_CODES.SSO_ERROR_MISSING_OPENID_AUTH_METHOD]:
    'errors.sso.missingAuthSettings',
  [ERROR_CODES.SSO_ERROR_USER_NOT_FOUND_AND_CAN_NOT_BE_CREATED]:
    'errors.sso.userNotFoundAndCanNotBeCreated',
  [ERROR_CODES.EMAIL_ALREADY_EXISTS]: 'errors.signup.email_exists',
  [ERROR_CODES.EMAIL_ALREADY_EXISTS_IAM]: 'errors.signup.email_exists',
  [ERROR_CODES.PASSWORD_INCORRECT]: 'errors.login.invalid_password',
  [ERROR_CODES.IAM_PASSWORD_INCORRECT]: 'errors.login.invalid_password',
  [ERROR_CODES.INVALID_EMAIL_OR_PASSWORD]:
    'errors.login.invalid_email_or_password',
  [ERROR_CODES.USER_DELETED_OR_BLOCKED]:
    'errors.login.blocked_or_deleted_account',
  [ERROR_CODES.USER_IS_BLOCKED]: 'errors.login.blocked_or_deleted_account',
  [ERROR_CODES.USER_IS_DELETED]: 'errors.login.blocked_or_deleted_account',
  [ERROR_CODES.LOGIN_NO_SUCH_MAIL]: 'errors.login.no_such_mail',
  [ERROR_CODES.LOGIN_NO_SUCH_MAIL_IAM]: 'errors.login.no_such_mail',
  [ERROR_CODES.RESET_PASSWORD_TOKEN_EXPIRED]:
    'errors.reset_password.link_expired',
  [ERROR_CODES.RESET_PASSWORD_TOKEN_ALREADY_USED]:
    'errors.reset_password.link_expired',
  [ERROR_CODES.OFAC_COUNTRIES_EMAIL_ERROR]:
    'errors.signup.ofac_countries_email',
  [ERROR_CODES.OFAC_COUNTRIES_LOCATION_ERROR]:
    'errors.signup.ofac_countries_location',
  [ERROR_CODES.SOCIAL_LOGIN_GET_EMAIL]: 'errors.general_server_error',
  [ERROR_CODES.RESET_PASSWORD_REQUIRED]: 'errors.login.reset_password_required',
  [ERROR_CODES.RESET_PASSWORD_REQUIRED_IAM]:
    'errors.login.reset_password_required',
  [ERROR_CODES.INVALID_TOKEN]: 'errors.recovery_phone.invalid_token',
  [ERROR_CODES.INVALID_CODE]: 'errors.recovery_phone.invalid_code',
  [ERROR_CODES.TOO_MANY_ATTEMPTS]: 'errors.recovery_phone.too_many_attempts',
  [ERROR_CODES.ACCOUNT_HAS_SSO_SETTINGS]: 'errors.recovery_phone.sso_settings',
  [ERROR_CODES.BLOCKED_DOMAIN]: 'errors.blocked_domain',
};

export const extractErrorKeyByErrorCode = (
  errorCode?: string,
  defaultError = 'errors.general',
) => {
  if (!errorCode) {
    return defaultError;
  }
  return ErrorCodesToKeys[errorCode] ?? defaultError;
};

export const areAllUserAccountsBlocked = (accountsData: AccountData[]) => {
  return accountsData.every((account) => account.isBlocked);
};
