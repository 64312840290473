import {
  emailDoesnTExistMessageDisplayed,
  loginSignupPageClickOnSwitchForm,
  loginSubmit,
  onSwitchContext,
} from '@wix/bi-logger-hls2/v2';
import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';
import { ROUTES } from '../routes';
import {
  AUTH_METHODS_BY_MAIL,
  CaptchaStatus,
  FORGOT_PASSWORD_LINK,
  GET_USER_ACCOUNTS_CONTEXT,
  LOGIN_PAGE_CONTEXT,
  SEARCH_PARAMS,
  SIGNUP_PAGE_CONTEXT,
  TWOFA_PROCESS,
} from '../utils/constants';
import { ERROR_CODES, extractErrorKeyByErrorCode } from '../utils/errorHandler';
import { createTextWithLink } from '../utils/generator';
import { Constraint, generateEmailFieldConstraints } from '../utils/validators';
import { CAPTCHA_ACTIONS } from './captcha';
import { FormField } from './formField';
import { RootStore } from './root';
import { SIGNUP_FLOWS } from './signup';
import { HttpError } from '@wix/http-client';
import { Values } from '../types';
import { SHOULD_BLOCK_EDITOR_X_SIGNUP_FT } from '../server/constants';
import { PasswordFormField } from './passwordFormField';
import webBiLogger from '@wix/web-bi-logger';
import { AUTH_TYPE } from '../components/BlockedAccount/authTypes';

const MAP_ERROR_CODE_TO_FIELD_TYPE = {
  [ERROR_CODES.PASSWORD_INCORRECT]: 'password',
  [ERROR_CODES.IAM_PASSWORD_INCORRECT]: 'password',
  [ERROR_CODES.INVALID_EMAIL_OR_PASSWORD]: 'password',
  [ERROR_CODES.USER_DELETED_OR_BLOCKED]: 'email',
  [ERROR_CODES.USER_IS_DELETED]: 'email',
  [ERROR_CODES.USER_IS_BLOCKED]: 'email',
  [ERROR_CODES.LOGIN_NO_SUCH_MAIL]: 'email',
  [ERROR_CODES.LOGIN_NO_SUCH_MAIL_IAM]: 'email',
  [ERROR_CODES.SOCIAL_LOGIN_GET_EMAIL]: 'email',
  [ERROR_CODES.GENERAL_ERROR_CODE]: 'email',
  [ERROR_CODES.RESET_PASSWORD_REQUIRED]: 'email',
  [ERROR_CODES.RESET_PASSWORD_REQUIRED_IAM]: 'email',
  [ERROR_CODES.SSO_LOGIN_MANDATORY_ERROR]: 'email',
};

export const LOGIN_FLOWS = {
  FROM_SIGNUP: 'FROM_SIGNUP',
  DEFAULT: 'DEFAULT',
};
export type ILoginFlows = Values<typeof LOGIN_FLOWS>;

const MAP_FLOWS_TO_SUBTITLE_KEYS = {
  [LOGIN_FLOWS.FROM_SIGNUP]: 'login.from_customized_signup.description',
  [LOGIN_FLOWS.DEFAULT]: undefined,
};

const MAP_FLOWS_TO_TITLE_KEYS = {
  [LOGIN_FLOWS.FROM_SIGNUP]: 'login.from_signup.title',
  [LOGIN_FLOWS.DEFAULT]: 'login.customized.title',
};

export class LoginStore implements AuthStore {
  private readonly rootStore: RootStore;
  private captchaAdded: CaptchaStatus = {
    [LOGIN_PAGE_CONTEXT]: false,
    [SIGNUP_PAGE_CONTEXT]: false,
    [GET_USER_ACCOUNTS_CONTEXT]: false,
  };
  public emailField: FormField;
  public isLoading: boolean = false;
  public passwordField: PasswordFormField;
  public readonly presetSubtitleKey?: string;
  private LOGIN_INTERACTION_NAME = 'email-login';
  public flow: string = LOGIN_FLOWS.DEFAULT;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const { i18n } = this.rootStore;
    const emailRules: Constraint[] = generateEmailFieldConstraints(
      i18n.t.bind(i18n),
    );
    this.emailField = new FormField(
      this.rootStore.userDataStore.email,
      emailRules,
    );
    this.passwordField = new PasswordFormField();
    this.presetSubtitleKey =
      this.rootStore.displayStore.preset?.login?.subtitleKey;
    makeObservable(this, {
      emailField: observable,
      passwordField: observable,
      isLoading: observable,
      titleKey: computed,
      subtitleKey: computed,
      login: action.bound,
      onInitFields: action.bound,
      clear: action.bound,
      onClickForgotPassword: action.bound,
    });
  }

  onNavigateToLogin(flow = LOGIN_FLOWS.DEFAULT) {
    this.flow = flow;
    this.onInitFields();
    this.rootStore.navigationStore.navigate(
      ROUTES.LOGIN_PASSWORD,
      this.flow === LOGIN_FLOWS.FROM_SIGNUP ? 'Login - from signup' : undefined,
    );
  }

  clear(): void {
    this.emailField.clear();
    this.passwordField.clear();
    this.captchaAdded = {
      [LOGIN_PAGE_CONTEXT]: false,
      [SIGNUP_PAGE_CONTEXT]: false,
      [GET_USER_ACCOUNTS_CONTEXT]: false,
    };
  }

  clearEmail(): void {
    this.clear();
    this.rootStore.navigationStore.navigate(ROUTES.EMAIL_STEP);
  }

  onInitFields(): void {
    this.passwordField.clear();
    this.captchaAdded = {
      [LOGIN_PAGE_CONTEXT]: false,
      [SIGNUP_PAGE_CONTEXT]: false,
      [GET_USER_ACCOUNTS_CONTEXT]: false,
    };
    const { email } = this.rootStore.userDataStore;
    email && this.emailField.setValue(email);
  }

  onClickForgotPassword() {
    this.rootStore.biLogger.report(
      onSwitchContext({
        context: 'forgot-password',
        referrer: this.rootStore.navigationStore.originContext,
      }),
    );
  }

  handleNoSuchEmailError() {
    const { userDataStore, signupStore, biLogger } = this.rootStore;
    biLogger.report(
      emailDoesnTExistMessageDisplayed({ context: 'email-login' }),
    );
    userDataStore.setEmail(this.emailField.value);
    this.isLoading = false;
    signupStore.onNavigateToSignup(SIGNUP_FLOWS.FROM_LOGIN);
  }
  get titleKey() {
    const { login } = this.rootStore.displayStore.preset;
    const mapper = MAP_FLOWS_TO_TITLE_KEYS;
    const defaultKey = login?.titleKey ?? mapper[LOGIN_FLOWS.DEFAULT];
    const useDefaultKey =
      this.flow === LOGIN_FLOWS.DEFAULT || !mapper[this.flow];
    return useDefaultKey ? defaultKey : mapper[this.flow];
  }

  get subtitleKey() {
    const mapper = MAP_FLOWS_TO_SUBTITLE_KEYS;
    const defaultKey = mapper[LOGIN_FLOWS.DEFAULT];
    const useDefaultKey =
      this.flow === LOGIN_FLOWS.DEFAULT || !mapper[this.flow];
    return useDefaultKey ? defaultKey : mapper[this.flow];
  }

  async login() {
    this.rootStore.biLogger.report(loginSubmit({ remember_me: true }));
    if (!this.isSubmittable) {
      this.markFormAsDirty();
      return;
    }
    this.isLoading = true;
    this.rootStore.fedopsLogger.interactionStarted(this.LOGIN_INTERACTION_NAME);
    const recaptchaParams =
      await this.rootStore.captchaStore.handleRecaptchaExecution({
        captchaAdded: this.captchaAdded[LOGIN_PAGE_CONTEXT],
        action: CAPTCHA_ACTIONS.LOGIN,
      });
    const { extendObjectFromQuery } = this.rootStore.apiStore;
    this.rootStore.apiStore
      .login(
        extendObjectFromQuery(
          {
            email: this.emailField.value,
            password: this.passwordField.value,
            rememberMe: true,
            ...recaptchaParams,
          },
          SEARCH_PARAMS.COLOR,
        ),
      )
      .then((data) => {
        this.rootStore.fedopsLogger.interactionEnded(
          this.LOGIN_INTERACTION_NAME,
        );
        this.rootStore.tagManagerStore.log('editorx.first.login', {
          email: this.emailField.value,
        });
        this.rootStore.biLogger = webBiLogger.factory().logger();
        this.rootStore.postLoginStore.biLogger = webBiLogger.factory().logger();
        return this.rootStore.navigationStore.postLogin(data?.payload ?? {});
      })
      .catch((error) => {
        const errorCode = error.errorCode.toString();
        if (errorCode !== ERROR_CODES.GENERAL_ERROR_CODE) {
          this.rootStore.fedopsLogger.interactionEnded(
            this.LOGIN_INTERACTION_NAME,
          );
        }
        if (errorCode === ERROR_CODES.SECOND_FACTOR_REQUIRED && error.payload) {
          this.rootStore.fedopsLogger.interactionStarted(TWOFA_PROCESS);
          this.rootStore.twoFactorAuthStore.setAuthParams(error.payload);
          this.rootStore.navigationStore.navigate(ROUTES.TWO_FACTOR_AUTH);
          this.isLoading = false;
          return;
        }
        if (
          errorCode === ERROR_CODES.LOGIN_NO_SUCH_MAIL ||
          errorCode === ERROR_CODES.LOGIN_NO_SUCH_MAIL_IAM
        ) {
          return this.handleNoSuchEmailError();
        }
        if (errorCode === ERROR_CODES.SSO_LOGIN_MANDATORY_ERROR) {
          if (error?.payload?.accountSsoLoginUrl) {
            return this.rootStore.navigationStore.redirect(
              error.payload.accountSsoLoginUrl,
            );
          }
        }
        // The user has no password, but he does have an account (or else we would get 'LOGIN_NO_SUCH_MAIL' error),
        // so we fetch more data to know what should be the correct next step
        if (this.shouldFetchAllAccountAfterFailedLogin(errorCode)) {
          return this.handleUserWithFailedLogin(errorCode);
        }
        this.isLoading = false;
        if (errorCode === ERROR_CODES.USER_IS_BLOCKED) {
          return this.rootStore.navigationStore.navigateToBlockedAccount({
            refferal_info: AUTH_TYPE.EMAIL_AND_PASSWORD,
          });
        }
        if (!this.rootStore.captchaStore.isCaptchaServerError(errorCode)) {
          this.rootStore.modalModeHandlerStore.handleErrorReport({ errorCode });
          this.addErrorToField(errorCode);
          if (
            errorCode === ERROR_CODES.RESET_PASSWORD_REQUIRED ||
            errorCode === ERROR_CODES.RESET_PASSWORD_REQUIRED_IAM
          ) {
            this.emailField.isWarning = true;
          }
        }
        this.captchaAdded[LOGIN_PAGE_CONTEXT] =
          this.rootStore.captchaStore.createOrResetCaptchaIfNeeded(
            errorCode,
            this.captchaAdded[LOGIN_PAGE_CONTEXT],
          );
      })
      .catch((error) => {
        this.rootStore.modalModeHandlerStore.handleErrorReport(error);
      });
  }

  shouldFetchAllAccountAfterFailedLogin(errorCode: string) {
    const errors = [
      ERROR_CODES.SSO_LOGIN_MANDATORY_ERROR,
      ERROR_CODES.INVALID_EMAIL_OR_PASSWORD,
      ERROR_CODES.PASSWORD_INCORRECT,
      ERROR_CODES.IAM_PASSWORD_INCORRECT,
    ];
    return errors.some((e) => e === errorCode);
  }

  async generalHandleUserWithNoPassword({
    captchaAdded,
    email,
    authFlowAdditionalParmas = {},
    originAuthMethod,
    originAuthError,
  }: {
    captchaAdded: boolean;
    email: string;
    authFlowAdditionalParmas?: { [key: string]: any };
    originAuthMethod?: string;
    originAuthError?: string;
  }) {
    const { captchaStore, apiStore, emailStepStore } = this.rootStore;
    const recaptchaParams = await captchaStore.handleRecaptchaExecution({
      captchaAdded,
      action: CAPTCHA_ACTIONS.GET_USER_ACCOUNTS,
    });

    try {
      const response = await apiStore.getUserAccounts({
        email,
        ...recaptchaParams,
      });

      const authType = emailStepStore.getAuthMethodByAccountData(
        response?.accountsData!,
        email,
      );

      if (authType.method === originAuthMethod) {
        return { success: false, errorCode: originAuthError };
      }
      emailStepStore.authHandlers[authType.method]({
        email,
        ...(authType.params ?? {}),
        ...authFlowAdditionalParmas,
      } as any);
      return { success: true };
    } catch (error) {
      return {
        success: false,
        errorCode: (error as HttpError)?.response?.data.errorCode?.toString(),
      };
    }
  }

  private async handleUserWithFailedLogin(loginErrorCode: string) {
    this.rootStore.userDataStore.setEmail(this.emailField.value);
    const { success, errorCode } = await this.generalHandleUserWithNoPassword({
      captchaAdded: this.captchaAdded[GET_USER_ACCOUNTS_CONTEXT],
      email: this.emailField.value,
      originAuthMethod: AUTH_METHODS_BY_MAIL.LOGIN_PASSWORD,
      originAuthError: loginErrorCode,
    });
    this.isLoading = false;
    if (!success) {
      this.addErrorToField(errorCode);
      this.captchaAdded[GET_USER_ACCOUNTS_CONTEXT] =
        this.rootStore.captchaStore.createOrResetCaptchaIfNeeded(
          errorCode,
          this.captchaAdded[GET_USER_ACCOUNTS_CONTEXT],
        );
    }
  }

  public shouldBlockEditorXSignup() {
    const shouldBlockEditorXSignup = this.rootStore.experiments.enabled(
      SHOULD_BLOCK_EDITOR_X_SIGNUP_FT,
    );
    return shouldBlockEditorXSignup && this.rootStore.displayStore.isEditorX;
  }

  private addErrorToField(errorCode: string) {
    const fields = {
      password: this.passwordField,
      email: this.emailField,
    };
    const errorElement = this.generateErrorElement(errorCode);
    const fieldWithError =
      MAP_ERROR_CODE_TO_FIELD_TYPE[errorCode] || 'password';
    fields[fieldWithError]?.addError(errorElement);
  }

  private generateErrorElement(errorCode: string): string | React.ReactElement {
    const errorKey = extractErrorKeyByErrorCode(errorCode);
    if (
      errorCode === ERROR_CODES.RESET_PASSWORD_REQUIRED ||
      errorCode === ERROR_CODES.RESET_PASSWORD_REQUIRED_IAM
    ) {
      const sessionId = this.rootStore.sessionId.get();
      return createTextWithLink(
        errorKey,
        FORGOT_PASSWORD_LINK(
          sessionId,
          this.rootStore.navigationStore.overrideLocale,
        ),
      );
    }
    return this.rootStore.i18n.t(errorKey);
  }

  public get isSubmittable(): boolean {
    return (
      this.emailField.isValid && this.passwordField.isValid && !this.isLoading
    );
  }

  private markFormAsDirty() {
    this.emailField.markFieldAsDirty();
    this.passwordField.markFieldAsDirty();
  }

  public reportSwitchToSignup() {
    this.rootStore.biLogger.report(
      loginSignupPageClickOnSwitchForm({
        switch_to: 'signup',
        context: 'login_second_step',
      }),
    );
  }
}
