import { RootStore } from './root';
import { HttpClient, HttpError, HttpResponse } from '@wix/http-client';
import {
  GetSsoSettingsResponse,
  UsersAccountsDataByEmailRequest,
} from '@wix/ambassador-wix-html-login-webapp/http';
import { getUserAccountsByEmail } from '@wix/ambassador-users-v1-example/http';
import {
  loginV2,
  registerV2,
} from '@wix/ambassador-iam-authentication-v1-authentication/http';
import {
  ApiResponseData,
  ExecuteStepUpAuthRequest,
  InitiateStepUpAuthRequest,
  AuthRequest,
  UserAccountsDataByEmailResponse,
  VerifyCodeAndLoginRequest,
  VerifyRecoveryCodeRequest,
  VerifyRecoveryCodeResponse,
  SignupAuthRequest,
} from '../types';
import {
  deviceVerify,
  deviceVerifyV2,
  invalidateUserCode,
} from '@wix/ambassador-identity-oauth-v1-refresh-token/http';
import {
  DeviceVerifyResponse,
  DeviceVerifyRequest,
  InvalidateUserCodeRequest,
  InvalidateUserCodeResponse,
} from '@wix/ambassador-identity-oauth-v1-refresh-token/types';
import { ERROR_CODES, extractServerErrorCode } from '../utils/errorHandler';
import { getChallenge } from '@wix/ambassador-identity-v1-verification/http';
import { GetChallengeResponse } from '@wix/ambassador-identity-v1-verification/types';
import {
  API_PATH,
  CAPTCHA_KEY,
  EXPERIMENTS,
  SEARCH_PARAMS,
  TwoFactorAuthMethod,
  USERS_API_PATH,
} from '../utils/constants';
import {
  sendAccountRecoveryCode,
  verifyAccountRecoveryCode,
} from '@wix/ambassador-identity-users-v1-user/http';
import {
  VerifyAccountRecoveryCodeRequest,
  VerifyAccountRecoveryCodeResponse,
} from '@wix/ambassador-identity-users-v1-user/types';
import { isAnswersUrl } from '../utils/general';

export class ApiStore {
  private readonly rootStore: RootStore;
  private readonly httpClient: HttpClient;
  private readonly AUTH_SERVER_PATH: string;
  private iamDisabled: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.httpClient = new HttpClient();
    this.extendObjectFromQuery = this.extendObjectFromQuery.bind(this);
    this.AUTH_SERVER_PATH = this.rootStore.experiments.enabled(
      'specs.ident.useOriginInApiCalls',
    )
      ? location.origin + '/auth/'
      : '/auth/';
  }

  private transformRequest(obj: any) {
    return new URLSearchParams(obj).toString();
  }

  private logHttpResponseToRecorder(response: HttpResponse) {
    const { data, headers, config } = response;
    const { email, password, userName, ...payloadToCollect } =
      data?.payload ?? {};
    const requestId = response.requestId ?? headers['x-wix-request-id'];
    this.rootStore.addAttributeToWixRecorder(
      config.url ?? requestId,
      JSON.stringify({
        data: { ...data, payload: payloadToCollect },
        requestId,
      }),
    );
  }

  private responseProxy(request: Promise<HttpResponse>) {
    return request
      .then((response) => {
        this.logHttpResponseToRecorder(response);
        return response;
      })
      .catch((error: HttpError) => {
        error.response && this.logHttpResponseToRecorder(error.response);
        throw error;
      });
  }

  public login(data: AuthRequest) {
    const shouldUseIAMPlatform = this.shouldUseIAMPlatform(
      EXPERIMENTS.USE_IAM_FOR_USERS_LOGIN_CALLS,
      data,
    );
    return this.conditionallyUseIAMWithFallback(
      shouldUseIAMPlatform,
      () => this.loginWithIAM(data),
      () => this.loginWithLegacy(data),
    );
  }

  private loginWithIAM(data: AuthRequest) {
    const { email, password, color } = data;
    const { userType } = this.rootStore;

    return this.responseProxy(
      this.httpClient.request(
        loginV2({
          loginId: {
            email,
          },
          password,
          captchaTokens: this.getCaptchas(data),
          clientMetaData: this.extendAuthParams(
            {
              color,
              userType,
            },
            true,
          ),
        }),
      ),
    )
      .then((response) => {
        return {
          success: true,
          data: response.data,
        };
      })
      .catch((error) => {
        const errorCode = error.response?.data?.details?.applicationError?.code;
        const statusCode = error.response?.status;

        // eslint-disable-next-line no-throw-literal
        throw {
          success: false,
          errorCode,
          statusCode,
        };
      });
  }

  public loginWithLegacy(data: AuthRequest) {
    return this.responseProxy(
      this.httpClient.post(
        `${this.AUTH_SERVER_PATH}v2/login`,
        this.extendAuthParams(data, false),
        {
          transformRequest: this.transformRequest,
          baseURL: `/${this.rootStore.userType}`,
        },
      ),
    ).then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw response.data;
    });
  }

  public signup(data: SignupAuthRequest) {
    this.addInviteTokenIfNeeded(data);
    const shouldUseIAMPlatform = this.shouldUseIAMPlatform(
      EXPERIMENTS.USE_IAM_FOR_USERS_CALLS,
      data,
    );
    const finalData = this.extendAuthParams(data, shouldUseIAMPlatform);

    return this.conditionallyUseIAMWithFallback(
      shouldUseIAMPlatform,
      () => this.signupWithIAM(finalData),
      () => this.signUpWithLegacy(finalData),
    );
  }

  private signupWithIAM(data: ExtendedSignupAuthRequest) {
    const { email, password, color } = data;
    const { userType } = this.rootStore;

    return this.responseProxy(
      this.httpClient.request(
        registerV2({
          loginId: {
            email,
          },
          password,
          captchaTokens: this.getCaptchas(data),
          profile: {
            customFields: [
              ...(data.color
                ? [
                    {
                      name: 'color',
                      value: {
                        strValue: data.color,
                      },
                    },
                  ]
                : []),
              {
                name: 'userType',
                value: {
                  strValue: this.rootStore.userType,
                },
              },
            ],
          },
          clientMetaData: this.extendAuthParams(
            {
              color,
              userType,
            },
            true,
          ),
        }),
      ),
    )
      .then((response) => {
        return {
          success: true,
          data: response.data,
        };
      })
      .catch((error) => {
        const errorCode = error.response?.data?.details?.applicationError?.code;
        const statusCode = error.response?.status;

        // eslint-disable-next-line no-throw-literal
        throw {
          success: false,
          errorCode,
          statusCode,
        };
      });
  }

  private signUpWithLegacy(data: ExtendedSignupAuthRequest) {
    return this.responseProxy(
      this.httpClient.post(`${this.AUTH_SERVER_PATH}register`, data, {
        transformRequest: this.transformRequest,
        baseURL: `/${this.rootStore.userType}`,
      }),
    ).then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw response.data;
    });
  }

  public verifyCodeAndLogin(data: VerifyCodeAndLoginRequest) {
    return this.responseProxy(
      this.httpClient.post(`${this.AUTH_SERVER_PATH}verifycodeandlogin`, data, {
        transformRequest: this.transformRequest,
      }),
    ).then((response) => response.data);
  }

  public verifyRecoveryCode(
    data: VerifyRecoveryCodeRequest,
  ): Promise<VerifyRecoveryCodeResponse> {
    return this.responseProxy(
      this.httpClient.post(`${this.AUTH_SERVER_PATH}verifyrecoverycode`, data, {
        transformRequest: this.transformRequest,
      }),
    ).then((response) => response.data);
  }

  public verifyRecoveryCodeNewApi(
    data: VerifyAccountRecoveryCodeRequest,
  ): Promise<VerifyAccountRecoveryCodeResponse> {
    return this.responseProxy(
      this.httpClient.request(verifyAccountRecoveryCode(data)),
    ).then((response) => response.data);
  }

  public initiateStepUpAuth(data: InitiateStepUpAuthRequest) {
    return this.responseProxy(
      this.httpClient.post('/_api/v1/users/initiate-authentication', data),
    ).then((response) => response.data);
  }

  public executeStepUpAuth(data: ExecuteStepUpAuthRequest) {
    return this.responseProxy(
      this.httpClient.post(
        `/_api/v1/users/execute-authentication/${data.verificationId ?? ''}`,
        data,
      ),
    ).then((response) => response.data);
  }

  public socialLogin<T extends AuthRequest>(
    data: T,
    provider: string,
  ): Promise<{
    data: ApiResponseData & { action?: string; studio?: boolean };
    headers: Function;
  }> {
    this.addInviteTokenIfNeeded(data);
    return this.responseProxy(
      this.httpClient.post(
        `/social/login/${provider}`,
        this.extendAuthParams(data, false),
        {
          transformRequest: this.transformRequest,
          baseURL: `/${this.rootStore.userType}`,
        },
      ),
    ).then((response) => response);
  }

  public resendRecoveryCode(email: string, parentAccountId?: string) {
    const shouldUseNewApi = this.rootStore.experiments.enabled(
      EXPERIMENTS.ACCOUNT_RECOVERY_NEW_API,
    );
    if (shouldUseNewApi) {
      return this.responseProxy(
        this.httpClient.request(
          sendAccountRecoveryCode({
            email,
            parentAccountId,
          }),
        ),
      ).then((response) => response.data);
    }
    return this.responseProxy(
      this.httpClient.post(
        `${this.AUTH_SERVER_PATH}sendrecoverycode?email=${email}${
          parentAccountId ? `&parentAccountId=${parentAccountId}` : ''
        }`,
        {},
        {
          transformRequest: this.transformRequest,
        },
      ),
    ).then((response) => response.data);
  }

  public resendSecondFactorCode(data: {
    twoFAToken: string;
    twoFAMethod: string;
    phoneDeliveryMethod?: string;
  }): Promise<
    ApiResponseData & {
      payload: {
        twoFAHint?: string;
        twoFAToken?: string;
        currentTwoFAMethod?: TwoFactorAuthMethod;
      };
    }
  > {
    return this.responseProxy(
      this.httpClient.post(`${this.AUTH_SERVER_PATH}resend2facode`, data, {
        transformRequest: this.transformRequest,
      }),
    ).then((res) => res.data);
  }

  public resetPassword(data: {
    emailToken: string;
    newPassword: string;
  }): Promise<ApiResponseData & { payload: {} }> {
    return this.responseProxy(
      this.httpClient.post(`${USERS_API_PATH}/link/changePasswordAjax`, data, {
        transformRequest: this.transformRequest,
      }),
    ).then((res) => res.data);
  }

  public async getUserSsoSettingsByEmail(
    email: string,
    parentAccountId?: string,
  ): Promise<GetSsoSettingsResponse> {
    return this.responseProxy(
      this.httpClient.get(
        `${API_PATH}ssoAuthUrl?email=${encodeURIComponent(email)}${
          parentAccountId
            ? `&parentAccountId=${encodeURIComponent(parentAccountId)}`
            : ''
        }`,
      ),
    ).then((res) => res.data);
  }

  public async getMissingRenderParams(): Promise<{
    basename: string;
    ssoRedirectUrl: string;
    ssoExistingWixAccountEmail: string;
    ssoAccountId: string;
    ssoAccountImage: string;
    ssoAccountName: string;
    currentLoggedInUserEmail: string;
  }> {
    return this.responseProxy(
      this.httpClient.get(`${API_PATH}missingRenderModel${location.search}`),
    ).then((res) => res.data);
  }

  public getUserAccounts(
    data: UsersAccountsDataByEmailRequest,
  ): Promise<UserAccountsDataByEmailResponse> {
    return this.responseProxy(
      this.httpClient.request(getUserAccountsByEmail(data)),
    ).then((res) => res.data as UserAccountsDataByEmailResponse);
  }

  public getFunnelRedirectUrl({
    precondition,
    postAuthUrl,
    redirectToUrl,
    authMethod,
    originUrl,
  }: {
    precondition: string;
    postAuthUrl: string;
    redirectToUrl: string;
    authMethod?: string;
    originUrl?: string;
  }): Promise<{ url: string }> {
    const preconditionParam = `precondition=${precondition}`;
    const postAuthUrlParam =
      postAuthUrl && postAuthUrl !== 'undefined'
        ? `&postAuthUrl=${postAuthUrl}`
        : '';
    const authMethodParam = authMethod ? `&authMethod=${authMethod}` : '';
    const redirectToUrlParam =
      redirectToUrl && redirectToUrl !== 'undefined'
        ? `&redirectToUrl=${redirectToUrl}`
        : '';
    const originUrlParam =
      originUrl && originUrl !== 'undefined' ? `&originUrl=${originUrl}` : '';
    const headers = this.rootStore.displayStore.getPresetHeaders();
    return this.httpClient
      .get(
        `/_api/funnel-intro-router-service/v2/intro?${preconditionParam}${postAuthUrlParam}${authMethodParam}${redirectToUrlParam}${originUrlParam}`,
        {
          headers,
          baseURL: '',
        },
      )
      .then((res: any) => res.data.introDestination);
  }

  public getCurrentPage(): Promise<{}> {
    return this.httpClient.get(window.location.href);
  }

  public deviceVerifyCode(
    code: Omit<DeviceVerifyRequest, 'userCode'>,
  ): Promise<DeviceVerifyResponse> {
    return this.httpClient
      .request(deviceVerifyV2({ userCode: code as string }))
      .then((res) => res.status);
  }

  public async getChallenge(
    verificationId: string,
  ): Promise<GetChallengeResponse> {
    return this.httpClient
      .request(getChallenge({ verificationId }))
      .then((res) => res.data);
  }

  public deviceInvalidateUserCode(
    code: Omit<InvalidateUserCodeRequest, 'userCode'>,
  ): Promise<InvalidateUserCodeResponse> {
    return this.httpClient
      .request(invalidateUserCode({ userCode: code as string }))
      .then((res) => res.status);
  }

  private addInviteTokenIfNeeded(data: { [key: string]: any }) {
    const inviteToken =
      this.rootStore.navigationStore.getQueryParam('inviteToken');
    if (inviteToken) {
      data.inviteToken = inviteToken;
    }
  }

  public extendAuthParams<T extends {}>(params: T = {} as T, useIAM: boolean) {
    if (useIAM) {
      return {
        ...params,
        ldSessionId: this.rootStore.sessionId.get(),
        originView: this.rootStore.displayStore.isMobile ? 'mobile' : 'desktop',
        urlThatUserRedirectedFrom: this.rootStore.originUrl,
      };
    }

    return {
      ...params,
      ldSessionID: this.rootStore.sessionId.get(),
      originView: this.rootStore.displayStore.isMobile ? 'mobile' : 'desktop',
      urlThatUserRedirectedFrom: this.rootStore.originUrl,
      overrideLocale: this.rootStore.language.locale,
      language: this.rootStore.language.locale,
    };
  }

  public extendObjectFromQuery<T extends { [key: string]: any }>(
    currentObject: T,
    paramKey: string,
    precondition?: (value: string) => boolean,
  ): T & { [k: typeof paramKey]: string | undefined } {
    const param = this.rootStore.navigationStore.getQueryParam(paramKey);
    Object.assign(currentObject, {
      ...((precondition && !precondition(param)) || !param
        ? {}
        : { [paramKey]: param }),
    });

    return currentObject;
  }

  // TODO when merging this FT we should also modify the ldSessionID param name
  private shouldUseIAMPlatform(spec: string, params: Record<string, any>) {
    return (
      this.rootStore.experiments.enabled(spec) &&
      !params.userToken &&
      !params.inviteToken &&
      !params.studio &&
      document.location.origin === 'https://users.wix.com' &&
      !isAnswersUrl(
        new URLSearchParams(location.search).get(
          SEARCH_PARAMS.POST_LOGIN_URL,
        ) ?? '',
      )
    );
  }

  public async conditionallyUseIAMWithFallback<T>(
    shouldUseIAMPlatform: boolean,
    iam: () => Promise<T>,
    fallback: () => Promise<T>,
  ): Promise<T> {
    if (!shouldUseIAMPlatform || this.iamDisabled) {
      return fallback();
    }

    try {
      return await iam();
    } catch (error) {
      const statusCode = (error as any)?.statusCode as number;
      let shouldFallback = false;

      if (statusCode >= 500 && statusCode < 600) {
        this.iamDisabled = true;
        shouldFallback = true;
      }

      if (
        shouldFallback ||
        extractServerErrorCode(error) === ERROR_CODES.UNIMPLEMENTED_FEATURE
      ) {
        return fallback();
      }

      throw error;
    }
  }

  private getCaptchas(data: AuthRequest) {
    if (data[CAPTCHA_KEY]) {
      return [{ Recaptcha: data[CAPTCHA_KEY] }];
    }
    return [{ InvisibleRecaptcha: data.invisibleRecaptcha }];
  }
}

type ExtendedSignupAuthRequest = ReturnType<
  typeof ApiStore.prototype.extendAuthParams<SignupAuthRequest>
>;
